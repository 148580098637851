import { cn } from "@/lib/utils";

export const CassiusLogo = ({ width = 124, className = "" }) => {
  const height = width * 0.316;

  return (
    <svg
      className={cn("", className)}
      width={width}
      height={height}
      viewBox='0 0 968 306'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M963.053 3.78062L964.443 1.5H961.772H808.172C794.335 1.5 774.893 6.77238 765.298 22.2081L765.294 22.2141L765.291 22.2202L594.891 302.22L593.503 304.5H596.172H749.772C763.614 304.5 783.063 299.224 792.655 283.377L963.053 3.78062ZM808.172 14.5H941.104L781.694 276.614C781.693 276.616 781.692 276.617 781.691 276.619C778.7 281.477 773.688 285.205 767.875 287.724C762.068 290.24 755.572 291.5 749.772 291.5H616.838L776.235 29.0086C776.238 29.0037 776.241 28.9989 776.245 28.9941C779.232 24.3343 784.242 20.7026 790.059 18.2305C795.871 15.7601 802.372 14.5 808.172 14.5Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M527.693 2.85999L527.566 1.5H526.2H73.3999C52.25 1.5 34.3527 8.72584 21.7392 21.3393C9.12574 33.9528 1.8999 51.8501 1.8999 73V233C1.8999 254.15 9.12574 272.047 21.7392 284.661C34.3527 297.274 52.25 304.5 73.3999 304.5H526.2H527.57L527.694 303.135L539.294 175.135L539.442 173.5H537.8H164.9V132.5H538.2H539.847L539.693 130.86L527.693 2.85999ZM151.9 185V186.5H153.4H525.361L516.027 291.5H73.3999C55.7408 291.5 41.1414 285.62 30.9606 275.439C20.7797 265.259 14.8999 250.659 14.8999 233V73C14.8999 55.3409 20.7797 40.7415 30.9606 30.5606C41.1414 20.3798 55.7408 14.5 73.3999 14.5H516.032L525.755 119.5H153.4H151.9V121V185Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='3'
      />
    </svg>
  );
};

export const CassiusLogoV2 = ({ width = 124, className = "" }) => {
  const height = width * 0.526;
  return (
    <svg
      width={width}
      height={height}
      className={cn("", className)}
      viewBox='0 0 133 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33 34.5302C35.64 34.5302 38.28 34.5302 41 34.5302C41.2205 35.4563 41.2205 35.4563 41.4453 36.4012C43.3497 43.592 45.8291 50.3824 51.9582 55.008C59.749 59.2963 67.4153 59.2636 76.0703 59.2372C77.6031 59.2503 79.1358 59.2656 80.6684 59.283C84.6699 59.3226 88.6708 59.333 92.6724 59.337C96.7688 59.3464 100.865 59.3839 104.961 59.4188C112.974 59.4831 120.987 59.5166 129 59.5302C127.941 62.9333 126.991 65.5437 125 68.5302C122.417 68.9246 122.417 68.9246 119.104 68.9501C117.245 68.9739 117.245 68.9739 115.349 68.9983C113.987 68.9996 112.624 68.9997 111.262 68.9989C109.861 69.006 108.46 69.0137 107.059 69.022C104.112 69.0354 101.166 69.037 98.2195 69.0311C94.4784 69.0253 90.7386 69.0556 86.9978 69.0962C84.0969 69.1224 81.1962 69.125 78.2952 69.1214C76.9187 69.123 75.5423 69.1325 74.1659 69.1505C62.8333 69.2822 51.0061 67.5445 42.4922 59.327C36.4366 52.0349 32.5303 44.1598 33 34.5302Z'
        fill='#a68a64'
      />
      <path
        d='M8 0.530136C10.5068 0.142456 10.5068 0.142456 13.7122 0.126083C14.9113 0.113399 16.1105 0.100715 17.346 0.0876465C18.6511 0.0905821 19.9562 0.0935178 21.3008 0.0965424C22.6568 0.0919783 24.0129 0.0866721 25.3689 0.080658C28.2214 0.0717045 31.0736 0.0741675 33.926 0.0840912C37.5459 0.0954684 41.1648 0.0755577 44.7845 0.046174C47.5928 0.027617 50.4009 0.0281446 53.2092 0.0338116C55.1813 0.0340359 57.1535 0.0172702 59.1256 0C70.4258 0.0859153 81.2538 1.92976 90.0625 9.47545C95.9504 15.7195 99.8108 23.3261 100.125 31.9676C100.084 32.8133 100.043 33.6589 100 34.5301C97.36 34.5301 94.72 34.5301 92 34.5301C91.853 33.8289 91.7061 33.1276 91.5547 32.4051C89.802 24.9916 87.4754 18.8625 81.1953 14.1864C75.8216 10.9575 71.4768 10.4048 65.3037 10.3248C64.6399 10.3149 63.9761 10.305 63.2921 10.2948C61.1062 10.2633 58.9203 10.2385 56.7344 10.2137C55.2155 10.1931 53.6966 10.1722 52.1778 10.1508C48.1863 10.0956 44.1947 10.0462 40.2031 9.99791C36.1276 9.94761 32.0521 9.89197 27.9766 9.83678C19.9844 9.72933 11.9923 9.62786 4 9.53014C5.05875 6.12701 6.00902 3.5166 8 0.530136Z'
        fill='#414833'
      />
      <path
        d='M50 34.5302C52.64 34.5302 55.28 34.5302 58 34.5302C58.4538 35.4995 58.9075 36.4689 59.375 37.4677C60.6579 40.6009 60.6579 40.6009 63 41.5302C65.2154 41.6511 67.4343 41.7076 69.6528 41.7355C70.3359 41.7454 71.0189 41.7553 71.7227 41.7655C73.9843 41.7971 76.246 41.8219 78.5078 41.8466C80.0744 41.8671 81.6409 41.8881 83.2075 41.9095C87.3338 41.9648 91.4602 42.0142 95.5867 42.0624C99.7961 42.1126 104.005 42.1683 108.215 42.2235C116.477 42.3311 124.738 42.4326 133 42.5302C132.67 45.5002 132.34 48.4702 132 51.5302C123.011 51.6463 114.023 51.735 105.034 51.7892C100.859 51.8152 96.6851 51.8505 92.5107 51.9073C88.4777 51.9619 84.4451 51.9916 80.4118 52.0045C78.8776 52.0137 77.3435 52.0317 75.8096 52.0588C61.3352 52.3043 61.3352 52.3043 55.4283 47.9485C52.1294 44.6726 50.152 41.3157 49.75 36.5927C49.8325 35.912 49.915 35.2314 50 34.5302Z'
        fill='#7f4f24'
      />
      <path
        d='M1 17.5302C9.85825 17.4373 18.7163 17.3663 27.575 17.3229C31.689 17.3021 35.8027 17.2739 39.9165 17.2284C43.8912 17.1848 47.8655 17.161 51.8404 17.1507C53.3522 17.1433 54.864 17.129 56.3756 17.1073C65.8641 16.9766 73.1231 17.0284 80.4375 23.7802C82.5228 27.4503 82.7154 30.333 83 34.5302C80.36 34.5302 77.72 34.5302 75 34.5302C74.5462 33.5608 74.0925 32.5914 73.625 31.5927C72.3421 28.4594 72.3421 28.4594 70 27.5302C67.7846 27.4092 65.5657 27.3527 63.3472 27.3249C62.6641 27.315 61.9811 27.3051 61.2773 27.2949C59.0157 27.2632 56.754 27.2385 54.4922 27.2138C52.9256 27.1932 51.3591 27.1722 49.7925 27.1508C45.6662 27.0956 41.5398 27.0462 37.4133 26.998C33.2039 26.9478 28.9945 26.8921 24.7852 26.8368C16.5235 26.7292 8.26178 26.6278 0 26.5302C0.33 23.5602 0.66 20.5902 1 17.5302Z'
        fill='#a4ac86'
      />
    </svg>
  );
};
