import * as React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";

const createApp = () =>
  createInertiaApp({
    resolve: (name) => {
      // @ts-ignore
      const pages = import.meta.glob(
        ["./pages/**/*.tsx", "!./pages/**/*.{test,spec}.tsx"],
        { eager: true }
      );
      return pages[`./pages/${snakeCaseString(name)}.tsx`];
    },
    setup({ el, App, props }) {
      createRoot(el).render(<App {...props} />);
    },
    progress: {
      delay: 250,
      color: "#29d",
      includeCSS: true,
      showSpinner: false,
    },
  });

const snakeCaseString = (str: string) => {
  let strs = str.split("/");
  return strs
    .map((str) => {
      return str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        ?.map((s: string) => s.toLowerCase())
        .join("_");
    })
    .join("/");
  // return (
  //   str
  //     .match(
  //       /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  //     )?.map((s: string) => s.toLowerCase())
  //     .join("_")
  // );
};

export default createApp;
