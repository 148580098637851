import * as React from "react";

export enum Theme {
  Dark = "dark",
}

interface ColorPalette {
  spotlight: string;
  "spotlight-foreground": string;
  background: string;
  foreground: string;
  "foreground-inverse": string;
  "muted-foreground": string;
  accent: string;
  border: string;
}

export const COLORS: Record<Theme, ColorPalette> = {
  [Theme.Dark]: {
    spotlight: "27deg 96.1% 69.6%",
    "spotlight-foreground": "0deg 0% 13.3%",
    background: "240deg 15.4% 5.1%",
    foreground: "220deg 13% 91%",
    "foreground-inverse": "240 5.9% 10%",
    "muted-foreground": "240 5% 64.9%",
    accent: "0deg 0% 15.3%",
    border: "240 3.7% 18.2%",
  },
};

export const useThemeColorPalette = (colorParette: ColorPalette) => {
  React.useEffect(() => {
    Object.entries(colorParette).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, [colorParette]);
};
