import * as React from "react";
import { cn } from "@/lib/utils";
import { COLORS, Theme, useThemeColorPalette } from "@/themes";

const ApplicationLayoutContext = React.createContext<
  { ref: React.RefObject<HTMLDivElement> } | undefined
>(undefined);

const Application = ({ children }: { children: React.ReactNode }) => {
  const ref = React.useRef(null);

  const theme = COLORS[Theme.Dark];

  useThemeColorPalette(theme);

  return (
    <ApplicationLayoutContext.Provider value={{ ref }}>
      <div
        ref={ref}
        className={cn(
          "bg-background font-inter text-foreground text-sm font-normal antialiased min-h-screen mx-auto"
        )}
      >
        {children}
      </div>
    </ApplicationLayoutContext.Provider>
  );
};

export const useApplicationLayoutContext = () => {
  const context = React.useContext(ApplicationLayoutContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationLayoutContext must be used within a ApplicationLayoutContext"
    );
  }
  return context;
};

export default Application;
